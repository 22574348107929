import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_ioBroker_Tutorials/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "ioBroker and MotionEye Webhook",
  "path": "/Frequently_Asked_Question/ioBroker_and_MotionEye_Webhook/",
  "dateChanged": "2020-03-24",
  "author": "Mike Polinowski",
  "excerpt": "How can I use the INSTAR alarm server with ioBroker?",
  "image": "../../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras",
  "category": "smarthome",
  "type": "ioBroker"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='FAQs - ioBroker and the MotionEye Webhook' dateChanged='2020-03-24' author='Mike Polinowski' tag='INSTAR IP Camera' description='How can I use the INSTAR alarm server with ioBroker and MotionEye?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/ioBroker_and_MotionEye_Webhook/' locationFR='/fr/Frequently_Asked_Question/ioBroker_and_MotionEye_Webhook/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "iobroker-and-instar-alarmserver",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#iobroker-and-instar-alarmserver",
        "aria-label": "iobroker and instar alarmserver permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ioBroker and INSTAR Alarmserver`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I would like to use the motion detection in `}<a parentName="p" {...{
        "href": "/en/Software/Linux/MotionEye/"
      }}>{`MotionEye`}</a>{` to trigger an alarm in ioBroker and access my INSTAR cameras via MQTT or CGI command.`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: We already discussed in our `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/ioBroker_and_INSTAR_Alarmserver/"
      }}>{`ioBroker and INSTAR alarm server FAQ`}</a>{`, how to use the INSTAR alarm server to switch variables in ioBroker. Motioneye offers exactly this function as well:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "747px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a4e31c1b2338f9ecfe24c35078397f00/d54e4/ioBroker_and_MotionEye_Webhook.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "114.34782608695653%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAIAAACEf/j0AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC3UlEQVQ4y3WUy27kNhBF+2Oyii2x+JJYD1KiqEdL3T2ZGSCTYLLNKv//AYHktt1GnIMLsha8ZKGK5Cnd/ui+/mQMLEIfYWZhEmYRFkLOC48XLhuNVy4bD8vJc+eld9aENojIbthHiTEyM8bcIgfiFolSH2LGlLErKJ3z/gSqgrpyzuWc+74f8lBKyTnP89ylpDmDxwbFNQGM0/ZVAADqpLUGrZumiTEOOQ9lmOe5lDKOJZcJUwEf2hCcswDqWAsaQB+cXqZ9H6VyztM8XbZtnud1XVOMePsrbL/r6snYHXNwhPbdrLW21saUhiP5PvdlGEiSGy42SEviWvLIrsU9CNw0DQC8m7Xed0WkaZpy3/V9L6kLMWtj1ZGxNvZV5kPabwCA977rEiESSyAG49oQQtvu9pdD9N1+erQppbz3pZRlXrZt68vouFMNpVyEGV7r9Ma72R+EEK6Xy7Kcd/MwOtrN3TAxkVLqczMAIGLOOcbYpU5EokSOqeWobIPE7VGh/z0ZAOoHmDnG1FLU9qXDn3C6V9kYDRoRifZKEWIUIZEgyVhvPmM3w4FSChHnZdnWdTnvXLatTLPkURkHoEApUPVHqVN45f6SEBEDHr0JoT1CDKENxEFy4BSkP9QhyenxDSJRyyxdx133MlLXtSwtUZtyUy5NXpph24Ph7LtyT/sFo1RSsBKfkVakM/EYSGoldR2rKj4/xeo5Vk/x+Umq51xV94JZY5S1g1J/BrqxfI/pe0rXppnGb7f1x1RXk3WzdZOxI5gZtGj9z6/VvVVG68roUqvUNPlyma/X85cv/br1X3+E843GxfeFpjPNKy+rLNdfSH7WD32ujelBb0qtLf5G/E3ijeQqfbHNo0bbTA1F1/5dP/TZGKOUGpEWxDPxFuNMXFCisRHgg5SKChjg9HbprLFMjMwtEYpwSkHEc6qNq7X+r5TWJ/eK956JhZj3f/O428QB2XnvPsM79y/L4i5prnlQcwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a4e31c1b2338f9ecfe24c35078397f00/e4706/ioBroker_and_MotionEye_Webhook.avif 230w", "/en/static/a4e31c1b2338f9ecfe24c35078397f00/d1af7/ioBroker_and_MotionEye_Webhook.avif 460w", "/en/static/a4e31c1b2338f9ecfe24c35078397f00/a0f4b/ioBroker_and_MotionEye_Webhook.avif 747w"],
              "sizes": "(max-width: 747px) 100vw, 747px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a4e31c1b2338f9ecfe24c35078397f00/a0b58/ioBroker_and_MotionEye_Webhook.webp 230w", "/en/static/a4e31c1b2338f9ecfe24c35078397f00/bc10c/ioBroker_and_MotionEye_Webhook.webp 460w", "/en/static/a4e31c1b2338f9ecfe24c35078397f00/0baab/ioBroker_and_MotionEye_Webhook.webp 747w"],
              "sizes": "(max-width: 747px) 100vw, 747px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a4e31c1b2338f9ecfe24c35078397f00/81c8e/ioBroker_and_MotionEye_Webhook.png 230w", "/en/static/a4e31c1b2338f9ecfe24c35078397f00/08a84/ioBroker_and_MotionEye_Webhook.png 460w", "/en/static/a4e31c1b2338f9ecfe24c35078397f00/d54e4/ioBroker_and_MotionEye_Webhook.png 747w"],
              "sizes": "(max-width: 747px) 100vw, 747px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a4e31c1b2338f9ecfe24c35078397f00/d54e4/ioBroker_and_MotionEye_Webhook.png",
              "alt": "ioBroker und INSTAR Alarmserver",
              "title": "ioBroker und INSTAR Alarmserver",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`At this point, simply enter the URL that we `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/ioBroker_and_INSTAR_Alarmserver/"
      }}>{`previously created`}</a>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://192.168.2.111:8087/set/mqtt.0.instar.10D1DC21F5DB.features.ptz.preset.raw?value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span></code></pre></div>
    <p>{`And the IP camera with the MQTT ID `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`10D1DC21F5DB`}</code>{` will move to the preset position 2 (value=1) every time MotionEye detects a movement.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      